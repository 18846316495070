.error403 .error403-body[data-v-14f967e4] {
  height: 100vh;
  width: 100vw;
  background: url(../../static/img/noRole.1d3901ca.png) no-repeat;
  background-position: center;
  position: relative;
}
.error403 .error403-body-backDoor[data-v-14f967e4] {
    color: #409eff;
    font-size: var(--rootFontSize);
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(0, 60%);
            transform: translate(0, 60%);
}
.error403 .error403-body-backDoor[data-v-14f967e4]:hover {
    cursor: pointer;
}
